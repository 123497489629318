<template>
  <v-container fluid>
    <!-- Encadre titre de la page -->
    <v-toolbar
      color="transparent"
      :dark="this.$store.state.darkTheme ? true : false"
      class="my-2"
    >
      <v-toolbar-title>
        <h1 v-if="!isMobile()" class="font-weight-light">User database</h1>
        <h2 v-if="isMobile()" class="font-weight-light">User database</h2>
      </v-toolbar-title>
      <v-btn
        absolute
        right
        :icon="isMobile()"
        :x-large="isMobile()"
        color="gred"
        @click="updateLdap()"
      >
        <span v-if="!isMobile()">update ldap &thinsp;</span>
        <v-icon>mdi-database-sync</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- Quelque chiffres sur les comptes Perseus -->
    <v-row class="mt-4">
      <v-col align="center" class="mx-6">  
        <v-row justify="space-around">
        <v-avatar
          color="primary"
          size="100"
        >
          <h1>{{nb_active}}</h1>
        </v-avatar>
        </v-row>
        <v-row justify="space-around">
          <h2 class="title">Actives</h2>
        </v-row>
      </v-col>
      <v-col align="center" class="mx-6">  
        <v-row justify="space-around">
        <v-avatar
          color="primary"
          size="100"
        >
          <h1>{{nb_pending}}</h1>
        </v-avatar>
        </v-row>
        <v-row justify="space-around">
          <h2 class="title">Pending</h2>
        </v-row>
      </v-col>
      <v-col align="center" class="mx-6">  
        <v-row justify="space-around">
        <v-avatar
          color="primary"
          size="100"
        >
          <h1>{{nb_expired}}</h1>
        </v-avatar>
        </v-row>
        <v-row justify="space-around">
          <h2 class="title">Expired</h2>
        </v-row>
      </v-col>
      <v-col align="center" class="mx-6">  
        <v-row justify="space-around">
        <v-avatar
          color="primary"
          size="100"
        >
          <h1>{{nb_disabled}}</h1>
        </v-avatar>
        </v-row>
        <v-row justify="space-around">
          <h2 class="title">Disabled</h2>
        </v-row>
      </v-col>
    </v-row>

  
    <!-- Barre de recherche -->
    <v-row class="mx-1">
      <v-col>
        <v-row align="center">
          <v-col>
            <v-row>
              <v-text-field
                v-model.lazy="Uid"
                label="User Uid"
                :dark="this.$store.state.darkTheme ? true : false"
                class="mx-4"
                @keyup.enter="search"
              />
              <v-select
                v-model.lazy="StatusSearch"
                :items="StatusList"
                label="User Status"
                class="mx-4"
                :dark="this.$store.state.darkTheme ? true : false"
                clearable
              />
              <v-text-field
                v-model.lazy="GroupNameSearch"
                label="Group Name"
                :dark="this.$store.state.darkTheme ? true : false"
                class="mx-4"
                @keyup.enter="search"
              />
              <v-text-field
                v-model.lazy="UserFirstname"
                label="User Firstname"
                :dark="this.$store.state.darkTheme ? true : false"
                class="mx-4"
                @keyup.enter="search"
              />
              <v-text-field
                v-model.lazy="UserName"
                label="User Name"
                :dark="this.$store.state.darkTheme ? true : false"
                class="mx-4"
                @keyup.enter="search"
              />
            </v-row>
          </v-col>
          <v-col
            v-if="!isMobile()"
            cols="1"
            class="text-right"
            align-self="end"
          >
            <v-btn color="primary" class="my-6" fab @click="search">
              <v-icon large>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isMobile()" class="mx-1">
          <v-btn
            block
            color="primary"
            :dark="this.$store.state.darkTheme ? true : false"
            @click="search"
          >
            Search &thinsp;
            <v-icon large>mdi-magnify</v-icon>
          </v-btn>
        </v-row>

        <v-divider :dark="this.$store.state.darkTheme ? true : false" />
      </v-col>
    </v-row>

    <!-- Resultat -->
    <v-row class="mx-2">
      <v-col :cols="isMobile()?12:4">
        <v-card
          class="overflow-y-auto"
          :color="this.$store.state.darkTheme ? card_bg_dark : card_bg_light"
          :max-height="this.$store.state.windowH * (2 / 4)"
          :dark="this.$store.state.darkTheme ? true : false"
        >
          <v-card-title class="text-muted">
            <span v-if="showPending"> List of {{ userlist.length }} pending account(s) </span>
            <span v-else> Number of results : {{ userlist.length }} </span>
            <v-spacer />
            <v-btn
              icon
              color="primary"
              @click="copyText(userlist)"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copy list</span>
              </v-tooltip>
            </v-btn>
          </v-card-title>
          
          <v-card-text>
            <li v-for="(user, key) in userlist" :key="key">
              <v-btn
                style="text-transform: none"
                :dark="$store.state.darkTheme ? true : false"
                text
                @click="getUser(user)"
              >
                {{ user }}
              </v-btn>
            </li>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col :cols="isMobile()?12:8">
        <!--
        <v-card
          class="overflow-y-auto"
          :color="this.$store.state.darkTheme ? card_bg_dark:card_bg_light"
          :max-height="this.$store.state.windowH * (3 / 4)"
          :dark="this.$store.state.darkTheme?true:false"
        >
        -->
        <v-card
          :color="this.$store.state.darkTheme ? card_bg_dark : card_bg_light"
          :dark="this.$store.state.darkTheme ? true : false"
        >
          <v-card-title>User information card</v-card-title>
          <!--
          <v-card-subtitle>{{ account.firstname }} {{ account.name }}</v-card-subtitle>
          -->
          <v-card-text>
            LDAP entry summary :
            <v-simple-table
              style="background-color: transparent"
              dense
              class="text-muted"
            >
              <tr>
                <th style="width: 150px">Login:</th>
                <td>{{ account.login }}</td>
              </tr>
              <tr>
                <th>Common name:</th>
                <td>{{ account.firstname }} {{ account.name }}</td>
              </tr>
              <tr>
                <th>Status:</th>
                <td>{{ account.infos.user_status.value }}</td>
              </tr>
              <tr>
                <th>Account Type:</th>
                <td>{{ account.accountType }}</td>
              </tr>
              <tr>
                <th>E-mail adress:</th>
                <td>
                  <a :href="`mailto:${account.mail}`">{{ account.mail }}</a>
                </td>
              </tr>
              <tr>
                <th>Pole:</th>
                <td>{{ account.pole }}</td>
              </tr>
              <tr>
                <th>Laboratory:</th>
                <td>{{ account.laboratory }}</td>
              </tr>
              <tr>
                <th>Contract type:</th>
                <td>{{ account.contract_type }}</td>
              </tr>
              <tr>
                <th>End of contract:</th>
                <td>{{ account.infos.contract_date.value }}</td>
              </tr>
              <tr>
                <th>Email Verified:</th>
                <td>{{ account.emailKey }}</td>
              </tr>
              <tr v-if="account.referent">
                <th>ReferentUid:</th>
                <td>{{ account.referent }}</td>
              </tr>
            </v-simple-table>
          </v-card-text>

          <v-card-text>
            User manager(s) :
            <v-simple-table
              style="background-color: transparent"
              class="text-muted"
              dense
            >
              <tr v-if="account.referent">
                <th valign="top" style="width: 135px">ReferentUid:</th>
                <td>
                  <li
                    v-for="(referent, key) in list_externReferent"
                    :key="key"
                    class="mx-4"
                  >
                    {{ referent[0] }}
                    &thinsp;
                    <a :href="`mailto:${referent[1]}`">{{ referent[1] }}</a>
                  </li>
                  <br />
                </td>
              </tr>
              <tr>
                <th valign="top" style="width: 135px">Project referent(s):</th>
                <td>
                  <li
                    v-for="(referent, key) in list_projectReferent"
                    :key="key"
                    class="mx-4"
                  >
                    {{ referent[0] }}
                    &thinsp;
                    <a :href="`mailto:${referent[1]}`">{{ referent[1] }}</a>
                  </li>
                  <br />
                </td>
              </tr>
              <tr>
                <th valign="top" style="width: 135px">Pole referent(s):</th>
                <td>
                  <li
                    v-for="(referent, key) in list_poleReferent"
                    :key="key"
                    class="mx-4"
                  >
                    {{ referent[0] }}
                    &thinsp;
                    <a :href="`mailto:${referent[1]}`">{{ referent[1] }}</a>
                  </li>
                </td>
              </tr>
            </v-simple-table>
          </v-card-text>

          <v-card-text>
            List of user's project(s) :
            <v-simple-table
              style="background-color: transparent"
              dense
              class="text-muted"
            >
              <tr>
                <th valign="top" style="width: 135px">As member:</th>
                <td>
                  <li
                    v-for="(project, key) in list_projectsAsMember"
                    :key="key"
                    class="mx-4"
                  >
                    <router-link :to="`/my-projects/project/${project}`">
                      {{ project }}
                    </router-link>
                  </li>
                  <br />
                </td>
              </tr>
              <tr>
                <th valign="top" style="width: 135px">As admin:</th>
                <td>
                  <li
                    v-for="(project, key) in list_projectsAsAdmin"
                    :key="key"
                    class="mx-4"
                  >
                    <router-link :to="`/my-projects/project/${project}`">
                      {{ project }}
                    </router-link>
                  </li>
                </td>
              </tr>
            </v-simple-table>
          </v-card-text>

          <!--
          <v-card-text>
            Project list as admin:
            <li
              v-for="(project,key) in list_projectsAsAdmin"
              :key="key"
              class="mx-4"
            >
              {{ project }}
            </li>
          </v-card-text>
          <v-card-text>
            Project list as member:
            <li
              v-for="(project,key) in list_projectsAsMember"
              :key="key"
              class="mx-4"
            >
              {{ project }}
            </li>
          </v-card-text>
-->

          <!-- TODO ICI ------------------------------------------------------------- -->
          <v-card-text>
            List of user's group(s) :
            <v-simple-table
              style="background-color: transparent"
              dense
              class="text-muted"
            >
              <li
                v-for="(group, key) in list_groupsAsMember"
                :key="key"
                class="mx-4"
              >
                {{ group }}
              </li>
            </v-simple-table>
          </v-card-text>
          <!-- TODO ICI ------------------------------------------------------------- -->

          <v-card-title>
            Admin action on user &thinsp;
            <v-icon>mdi-database-edit</v-icon>
          </v-card-title>
          <v-card-text>
            <v-btn
              v-if="activate"
              absolute
              top
              right
              color="gred"
              @click="activateAccount"
            >
              Activate &thinsp;
              <v-icon>mdi-account-check</v-icon>
            </v-btn>
            <v-btn
              v-if="account.login"
              class="mx-2 my-2"
              color="gred"
              @click="addDialog = true"
            >
              Add to a project
            </v-btn>
            <v-btn
              v-if="account.login"
              class="mx-2 my-2"
              color="gred"
              @click="AddGroupDialog = true"
            >
              Edit groups
            </v-btn>
            <v-btn
              v-if="account.login"
              class="mx-2 my-2"
              color="gred"
              @click="dateDialog = true"
            >
              Update contract date
            </v-btn>
            <v-btn
              v-if="account.login"
              class="mx-2 my-2"
              color="gred"
              @click="emailDialog = true"
            >
              Update e-mail adress
            </v-btn>
            <v-btn
              v-if="account.emailKey == 'false'"
              class="mx-2 my-2"
              color="gred"
              @click="resendEmailKey()"
            >
              Resend Email validation
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br />

    <!-- Definition des boites de dialogue de la page -->
    <!-- Boite de dialogue : action en cours -->
    <v-dialog v-model="updated" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Boite de dialogue : reponse du serveur -->
    <v-dialog v-model="reponseDialog" persistent width="500">
      <v-card>
        <v-card-title>
          Server answer
          <v-spacer />
          <v-btn color="primary" icon @click="dismiss">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ reponse }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Boite de dialogue : ajout dans un projet -->
    <v-dialog v-model="addDialog" width="500">
      <v-card>
        <v-card-title class="headline primary" primary-title>
          Add {{ account.login }} to a project
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-select
              v-model="projectName"
              :items="this.$store.state.allActiveProjects"
              label="Select the project you want to join"
              full-width
              clearable
              clear-icon="fas fa-times"
              append-icon="fas fa-chevron-down"
            />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          Add &thinsp;
          <v-btn color="gred" @click="addAdminToProject"> As Admin </v-btn>
          &thinsp; or
          <v-btn color="green" @click="addMemberToProject"> As Member </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boite de dialogue : ajout dans un groupe -->
    <v-dialog v-model="AddGroupDialog" width="500">
      <v-card>
        <v-card-title class="headline primary" primary-title>
          Add {{ account.login }} to a group
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-select
              v-model="groupName"
              :items="this.$store.state.allGroups"
              label="Select the groupName you want to join"
              full-width
              clearable
              clear-icon="fas fa-times"
              append-icon="fas fa-chevron-down"
            />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="red" @click="deleteMemberToGroup">
            Delete Member
          </v-btn>
          &thinsp; or
          <v-btn color="green" @click="addMemberToGroup"> Add member </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boite de dialogue : modificateur de la contract date -->
    <v-dialog v-model="dateDialog" width="500">
      <v-card>
        <v-card-title class="headline primary">
          Modify contractDate for {{ account.login }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="contractdate"
                style="font-color: white"
                label="Contract date"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="contractDate"
              scrollable
              :min="String(today)"
            />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="gred" @click="setPermanent"> Set Permanent </v-btn>
          <v-spacer />
          <v-btn color="green" @click="updateContractDate"> Modify </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boite de dialogue : modificateur du mail -->
    <v-dialog v-model="emailDialog" width="500">
      <v-card>
        <v-card-title class="headline primary" primary-title>
          Change email for {{ account.login }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model.lazy="newMail"
              label="New mail"
              class="mx-4"
              :rules="emailRules"
            />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="green" @click="updateEmail"> Modify </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <br />
  </v-container>
</template>

<script>
import config from "@/config";
import axios from "axios";
import store from "../store";
import checkToken from "../store/checkToken";
export default {
  data: () => ({
    // Couleur
    red: config.colors.gricad_red_dark1,
    card_bg_dark: config.colors.card_bg_dark,
    card_bg_light: config.colors.card_bg_light,
    barchart_color: config.colors.gricad_blue_dark3,
    blue: config.colors.gricad_oceanblue,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    // Initialisation des v-model
    Uid: "",
    UserName: "",
    UserFirstname: "",
    reponseDialog: false,
    today: new Date().toISOString().substr(0, 10),
    groupName: "",
    activate: false,
    addDialog: false,
    dateDialog: false,
    emailDialog: false,
    AddGroupDialog: false,
    showPending: true,
    listAllPoles: [
      { title: "group" },
      { title: "Laboratories" },
      { title: "Project" },
      { title: "Mailling List" },
      { title: "Functionnal Group" },
      { title: "Machine" },
    ],
    valuePole: false,
    menu1: "group",
    userlist: [],
    nb_active: null,   
    nb_pending: null,  
    nb_disabled: null,  
    nb_expired: null,  
    list_groupsAsMember: [],
    list_projectsAsMember: [],
    list_projectsAsAdmin: [],
    list_projectReferent: [],
    list_poleReferent: [],
    list_externReferent: [],
    projectName: "",
    contractDate: "",
    newMail: "",
    GroupNameSearch: "",
    StatusSearch: "",
    StatusList: ["active", "pending", "disabled", "expired"],
    reponse: "",
    updated: false,
    account: {
      login: "",
      name: "",
      firstname: "",
      mail: "",
      contract_type: "",
      pole: "",
      laboratory: "",
      referent: "",
      accountType: "",
      emailKey: "",
      infos: {
        user_status: {
          name: "Account status",
          value: "",
        },
        expire_date: {
          name: "Expiration date",
          value: "",
        },
        contract_date: {
          name: "Contract date",
          value: "",
        },
        charter_date: {
          name: "Charter date",
          value: "",
        },
      },
    },
  }),
  created() {
    var token = $cookies.get("token");
    var validToken = checkToken(token);
    if (!validToken) {
      this.$router.push("/login");
    } else {
      axios.get("/getall-pendingAccount").then((response) => {
        this.userlist = response.data;
      });
      // TODO Informations resume sur les comptes
      axios.get("/get-nbActiveAccount").then((response) => {
        this.nb_active = response.data;
      });
      axios.get("/get-nbPendingAccount").then((response) => {
        this.nb_pending = response.data;
      });
      axios.get("/get-nbDisabledAccount").then((response) => {
        this.nb_disabled = response.data;
      });
      axios.get("/get-nbExpiredAccount").then((response) => {
        this.nb_expired = response.data;
      });
      // init la liste des projets actifs
      if (this.$store.state.allActiveProjects.length === 0) {
        this.$store.state.allActiveProjects = [];
        axios.get("/getall-activesProjects").then((response) => {
          var listprojects = response.data;
          for (var i = 0; i < listprojects.length; i++) {
            var project = listprojects[i];
            this.$store.state.allActiveProjects.push(project);
          }
          this.$store.state.allActiveProjects.sort();
        });
      }
      if (this.$store.state.allGroups.length === 0) {
        this.$store.state.allGroups = [];
        axios.get("/getall-groups").then((response) => {
          var listgroups = response.data;
          for (var i = 0; i < listgroups.length; i++) {
            var group = listgroups[i];
            this.$store.state.allGroups.push(group);
          }
          this.$store.state.allGroups.sort();
        });
      }
    }
  },
  methods: {
    copyText(param_text) {
      navigator.clipboard.writeText(param_text);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    dismiss() {
      this.reponse = "";
      this.reponseDialog = false;
    },
    style_listUser() {
      return {
        height: `${this.$store.state.windowH * (3 / 4)}px`,
      };
    },
    style_table_userInfos() {
      return {
        "min-width": "100px",
        "max-width": "200px",
        "background-color": "green",
      };
    },
    updateLdap() {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        axios.get("/update");
        this.reponse = "The update is in progress. You can close the window.";
        this.reponseDialog = true;
      }
    },
    resendEmailKey() {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        axios
          .get("/username/" + this.account.login + "/send-emailKey")
          .then((response) => {
            this.reponse = response.data;
            this.reponseDialog = true;
          });
      }
    },
    activateAccount() {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        axios
          .get("/username/" + this.account.login + "/activate-account")
          .then((response) => {
            if (response.data.includes("SUCCESS")) {
              this.account.infos.user_status.value = "Active";
              this.activate = false;
              if (this.userlist.includes(this.account.login)) {
                this.userlist.splice(
                  this.userlist.indexOf(this.account.login),
                  1
                );
              }
            }
            this.reponse = response.data;
            this.reponseDialog = true;
          });
      }
    },
    getUser(user) {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        this.activate = false;
        axios.get("/username/" + user).then((response) => {
          var accountLocal = response.data;
          this.account.login = accountLocal.uid[0];
          this.account.name = accountLocal.sn[0];
          this.account.firstname = accountLocal.givenName[0];
          this.account.mail = accountLocal.mail[0];
          this.account.contract_type = accountLocal.employeeType[0];
          if (accountLocal.emailKey != null) {
            this.account.emailKey = "false";
          } else {
            this.account.emailKey = "true";
          }
          if (accountLocal.referentUid) {
            this.account.referent = accountLocal.referentUid;
            // Recuperation de la liste des referentUid
            axios
              .get("/get-referentUid-cn&mail/" + this.account.login)
              .then((response) => {
                this.list_externReferent = response.data;
              });
          } else {
            this.account.referent = "";
            this.list_externReferent = [];
          }
          axios
            .get(
              "/username/" +
                this.account.login +
                "/getall-groups4user-ofType/p-*"
            )
            .then((response) => {
              this.account.pole = response.data[0];
              if (this.account.pole) {
                // Recuperation des pole leader
                axios
                  .get("/get-poleLeader-cn&mail/" + this.account.pole)
                  .then((response) => {
                    this.list_poleReferent = response.data;
                  });
              } else {
                this.list_poleReferent = [];
              }
            });
          axios
            .get(
              "/username/" +
                this.account.login +
                "/getall-groups4user-ofType/l-*"
            )
            .then((response) => {
              this.account.laboratory = response.data[0];
            });
          if (accountLocal.shadowExpire != null) {
            this.account.infos.expire_date.value = this.formatDate(
              accountLocal.shadowExpire[0] * 24 * 3600 * 1000
            );
          }
          if (Number(accountLocal.contractDate[0]) === 0) {
            this.account.infos.contract_date.value = "Permanent";
          } else {
            this.account.infos.contract_date.value = this.formatDate(
              accountLocal.contractDate[0] * 1000
            );
          }
          this.account.infos.charter_date.value = this.formatDate(
            accountLocal.charterDate[0] * 1000
          );

          // Recuperation de la liste des projets de l'utilisateur
          axios
            .get("/username/" + this.account.login + "/getall-projects4user")
            .then((response) => {
              this.list_projectsAsMember = response.data;

              // Recuperation du Status de l'utilisateur
              axios
                .get("/username/" + user + "/get-status")
                .then((responseUser) => {
                  this.account.infos.user_status.value = responseUser.data;
                  if (this.list_projectsAsMember != null) {
                    for (
                      var i = 0;
                      i < this.list_projectsAsMember.length;
                      i++
                    ) {
                      if (this.activate) {
                        break;
                      }
                      axios
                        .get(
                          "/project/" +
                            this.list_projectsAsMember[i] +
                            "/get-projectStatus"
                        )
                        .then((responseProject) => {
                          if (
                            responseProject.data == "Active" &&
                            responseUser.data === "Pending" &&
                            accountLocal.emailKey == null
                          ) {
                            this.activate = true;
                          }
                        });
                    }
                  }

                  // Recuperation du type de compte : externe / interne - agalan / non agalan / pending
                  axios
                    .get("/username/" + this.account.login + "/check-isExtern")
                    .then((response) => {
                      if (response.data) {
                        this.account.accountType = "Externe";
                      } else {
                        this.account.accountType = "Interne";
                      }
                      if (this.account.infos.user_status.value !== "Pending") {
                        axios
                          .get(
                            "/username/" +
                              this.account.login +
                              "/check-isAgalan"
                          )
                          .then((response) => {
                            if (response.data) {
                              this.account.accountType += " - Agalan";
                            } else {
                              this.account.accountType += " - non agalan";
                            }
                          });
                      }
                    });
                });

              if (this.list_projectsAsMember) {
                // recuperation des admins pour creer une liste de referents
                axios
                  .post("/get-projectAdmin-cn&mail/list-of-project", {
                    listOfProject: this.list_projectsAsMember,
                  })
                  .then((response) => {
                    this.list_projectReferent = response.data;
                  });
              } else {
                this.list_projectReferent = [];
              }
            });
          axios
            .get(
              "/username/" + this.account.login + "/getall-projects4userAdmin"
            )
            .then((response) => {
              this.list_projectsAsAdmin = response.data;
            });

          // Recuperation de list_groupsAsMember depuis /username/<uid>/get-userGroups
          axios
            .get("/username/" + this.account.login + "/get-userGroups")
            .then((response) => {
              this.list_groupsAsMember = response.data;
            });
        });
      }
    },
    addMemberToProject() {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        axios
          .get(
            "/project/" + this.projectName + "/add-member/" + this.account.login
          )
          .then((response) => {
            if (String(response.data).includes("[SUCCESS]")) {
              if (this.list_projectsAsMember == null) {
                this.list_projectsAsMember = [this.projectName];
                if (
                  this.list_projectsAsMember !== null &&
                  this.account.infos.user_status.value === "Pending" &&
                  this.account.emailKey == null
                ) {
                  this.activate = true;
                }
              } else {
                this.list_projectsAsMember.push(this.projectName);
              }
            }
            this.projectName = "";
            this.AddDialog = false;
            this.reponse = response.data;
            this.reponseDialog = true;
          });
      }
    },
    addAdminToProject() {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        axios
          .get(
            "/project/" + this.projectName + "/add-admin/" + this.account.login
          )
          .then((response) => {
            if (String(response.data).includes("[SUCCESS]")) {
              if (this.list_projectsAsAdmin == null) {
                this.list_projectsAsAdmin = [this.projectName];
              } else {
                this.list_projectsAsAdmin.push(this.projectName);
              }
            }
            this.projectName = "";
            this.AddDialog = false;
            this.reponse = response.data;
            this.reponseDialog = true;
          });
      }
    },
    addMemberToGroup() {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        axios
          .get("/group/" + this.groupName + "/add-member/" + this.account.login)
          .then((response) => {
            this.groupName = "";
            this.AddGroupDialog = false;
            this.reponse = response.data;
            this.reponseDialog = true;
          });
      }
    },
    deleteMemberToGroup() {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        axios
          .get(
            "/group/" + this.groupName + "/delete-member/" + this.account.login
          )
          .then((response) => {
            this.groupName = "";
            this.AddGroupDialog = false;
            this.reponse = response.data;
            this.reponseDialog = true;
          });
      }
    },
    setPermanent() {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        axios
          .get("/username/" + this.account.login + "/set-permanent")
          .then((response) => {
            this.contractDate = "";
            this.dateDialog = false;
            this.reponse = response.data;
            this.reponseDialog = true;
            this.account.infos.contract_date.value = "Permanent";
          });
      }
    },
    updateContractDate() {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        var date = this.contractDate.split("-");
        date = String(date[2] + "-" + date[1] + "-" + date[0]);
        axios
          .get("/username/" + this.account.login + "/set-contractDate/" + date)
          .then((response) => {
            this.contractDate = "";
            this.dateDialog = false;
            this.reponse = response.data;
            this.reponseDialog = true;
            this.account.infos.contract_date.value = date
              .replace("-", "/")
              .replace("-", "/");
          });
      }
    },
    updateEmail() {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        axios
          .get("/username/" + this.account.login + "/set-mail/" + this.newMail)
          .then((response) => {
            this.account.mail = this.newMail;
            this.newMail = "";
            this.emailDialog = false;
            this.reponse = response.data;
            this.reponseDialog = true;
          });
      }
    },
    search() {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        this.userlist = [];
        this.showPending = false;
        if (this.Uid) {
          axios.get("/search-account/" + this.Uid).then((response) => {
            if (String(response.data).includes("ERROR")) {
              this.reponse = response.data;
              this.reponseDialog = true;
            } else {
              if (this.userlist.length) {
                this.userlist = this.userlist.filter((x) =>
                  response.data.includes(x)
                );
              } else {
                this.userlist = response.data;
              } // Vider tous les filtres
            }
          });
        } else {
          // Recherche par status
          if (this.StatusSearch) {
            axios
              .get("/getall-" + this.StatusSearch + "Account")
              .then((response) => {
                if (String(response.data).includes("ERROR")) {
                  this.reponse = response.data;
                  this.reponseDialog = true;
                } else {
                  if (this.userlist.length) {
                    this.userlist = this.userlist.filter((x) =>
                      response.data.includes(x)
                    );
                  } else {
                    this.userlist = response.data;
                  }
                }
              });
          }

          // Recherche par groupe
          if (this.GroupNameSearch) {
            axios
              .get("/searchUserBy-groupName/" + this.GroupNameSearch)
              .then((response) => {
                if (String(response.data).includes("ERROR")) {
                  this.reponse = response.data;
                  this.reponseDialog = true;
                } else {
                  if (this.userlist.length) {
                    this.userlist = this.userlist.filter((x) =>
                      response.data.includes(x)
                    );
                  } else {
                    this.userlist = response.data;
                  }
                  // this.userlist = response.data
                  // this.GroupNameSearch = ''
                }
              });
          }

          // Recherche par nom de famille
          if (this.UserName) {
            axios.get("/searchUserBy-sn/" + this.UserName).then((response) => {
              if (String(response.data).includes("ERROR")) {
                this.reponse = response.data;
                this.reponseDialog = true;
              } else {
                if (this.userlist.length) {
                  this.userlist = this.userlist.filter((x) =>
                    response.data.includes(x)
                  );
                } else {
                  this.userlist = response.data;
                }
                // this.userlist = response.data
                // this.UserName = ''
              }
            });
          }

          // Recherche par prenom
          if (this.UserFirstname) {
            axios
              .get("/searchUserBy-givenName/" + this.UserFirstname)
              .then((response) => {
                if (String(response.data).includes("ERROR")) {
                  this.reponse = response.data;
                  this.reponseDialog = true;
                } else {
                  if (this.userlist.length) {
                    this.userlist = this.userlist.filter((x) =>
                      response.data.includes(x)
                    );
                  } else {
                    this.userlist = response.data;
                  }
                  // this.userlist = response.data
                  // this.UserFirstname = ''
                }
              });
          }
          // Filtre de recherhe vide (tous les resultats)
          if (
            this.GroupNameSearch === "" &&
            this.Uid === "" &&
            this.UserName === "" &&
            this.UserFirstname === "" &&
            (this.StatusSearch === "" || this.StatusSearch == null)
          ) {
            axios.get("/getall-user").then((response) => {
              if (String(response.data).includes("ERROR")) {
                this.reponse = response.data;
                this.reponseDialog = true;
              } else {
                this.userlist = response.data;
              }
            });
          }
        }
      }
    },
    formatDate(date) {
      var d = new Date(date);
      var month = "" + (d.getMonth() + 1);
      var day = "" + d.getDate();
      var year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [day, month, year].join("/");
    },
  },
  beforeRouteEnter(to, from, next) {
    var admin = store.state.admin;
    var reviewer = store.state.reviewer;
    if (!(admin || reviewer)) {
      next("/");
    } else {
      next();
    }
  },
};
</script>

<style lang="scss">
.text-muted {
  opacity: 0.7;
}
tr {
  border-spacing: 0 25px;
}
tr.spaceUnder > td {
  padding-bottom: 1em;
}
</style>
